import React, { useState } from "react"
import { slipStreamData } from '@util/whelpsData'
import "./slipstream.scss"

const ContentItem = ({ item }) => {

  return (
    <div className="flex-initial items-start gap-4 text-xs sm:text-sm md:text-md lg:text-lg">
      <div className="flex text-yellow-dark w-full uppercase ">
        <label className="text-3xl md:text-4xl lg:text-5xl flex-shrink-0">?</label>
        <div className="flex flex-col ml-2 pr-0 font-aAhaWow">
          <p className="font-bold">{item.title}</p>
          <p className="text-yellow-light break-words">{item.content}</p>
        </div>
      </div>
    </div>
  )
}

export default function Slipstream() {
  const [index, setIndex] = useState(0)
  const backgrounds = ["/slipstream/bg_1.png", "/slipstream/bg_2.png", "/slipstream/bg_3.png", "/slipstream/bg_4.png", "/slipstream/bg_5.png"]
  const dragons = ["/slipstream/dragon_1.png", "/slipstream/dragon_2.png", "/slipstream/dragon_3.png", "/slipstream/dragon_4.png", "/slipstream/dragon_5.png"]
  const mask = ["/slipstream/mask_1.png", "/slipstream/mask_2.png", "/slipstream/mask_3.png", "/slipstream/mask_4.png", "/slipstream/mask_5.png"]

  const handleNext = (next) => {
    setIndex(next ? index < backgrounds.length - 1 ? index + 1 : 0 : index > 0 ? index - 1 : backgrounds.length - 1)
  }

  return (
    <div className="flex flex-col">
      <div className="relative bg-black mt-10">
        <img src="/slipstream/top_back.png" alt="top back" className="md:block hidden" />
        <img src="/slipstream/top_back_mobile.png" alt="top back" className="block md:hidden" />
        <div className="md:absolute bottom-0 left-0 right-0 md:mt-40 lg:mb-12 xl:mb-28 py-2 sm:py-10 px-8 md:px-12 xl:px-32 border border-white header-title-container">
          <p className="font-lemonada text-center text-white text-sm sm:text-lg xl:text-2xl header-title font-bold">
            SlipStream is an upcoming P2E Mario Kart/Crash Team Racing style PC game. The game is being developed by MasterBrews and Argentics Studios and set to be released in Q2, 2022. The game will feature exciting tracks, powerups and special abilities dependant on which WhelpS you hold and decide to race with. Stay tuned for frequent updates and sneak peaks!
          </p>
        </div>
      </div>
      <div className="relative">
        <div className="relative pt-24 xl:pt-32 pb-10 opacity-0">
          <img src="/slipstream/box_bg.png" alt="box back" className="sm:block hidden" />
          <img src="/slipstream/box_mobile_long.png" alt="box back" className="sm:hidden block" />
        </div>
        {backgrounds.map((item, idx) => (
          <div className={`detail-container top-0 bottom-0 left-0 right-0 absolute ${index === idx && "active z-10"}`} style={{ backgroundImage: "url(" + item + ")" }} key={idx}>
            <div className="relative pt-20 sm:pt-24 xl:pt-32 pb-10">
              {mask.map((item, idx) => (
                <img src={item} alt="mask" className={`absolute left-0 top-0 z-10 w-1/3 sm:w-1/4 ${index !== idx && "hidden"}`} key={idx} />
              ))}

              <div className="absolute right-3 lg:right-10 top-5 lg:top-10 flex flex-col gap-2 w-1/2 sm:w-1/3 lg:w-1/4">
                <div className="flex justify-between relative items-center">
                  <img src="/slipstream/seekbar.png" />
                  <img src={dragons[index]} alt="dragon" className="absolute left-2 sm:left-5 w-8 sm:w-12 lg:w-max" />
                  <img src="/slipstream/flag.png" className="absolute right-2 w-8 lg:w-max" />
                </div>
                <span className="text-right text-xl lg:text-4xl text-white font-bold font-aAhaWow">{10}%</span>
              </div>
              <div className="flex justify-around" >
                <div className="flex w-16 sm:w-28 items-center justify-center">
                  <button className="outline-none w-full" onClick={() => handleNext(false)}>
                    <img src="/slipstream/btn_prev.png" alt="btn prev" />
                  </button>
                </div>
                <div className="relative">
                  <img src="/slipstream/box_bg.png" alt="box back" className="sm:block hidden" />
                  <img src="/slipstream/box_mobile_long.png" alt="box back" className="sm:hidden block" />
                  <div className="-translate-x-1/2 absolute flex justify-center text-center font-aAhaWow items-center left-1/2 mx-auto text-white w-9/12 sm:w-1/2 top-0 transform px-8 py-2 text-xs sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl whitespace-nowrap  -translate-y-1/2">
                    <img src="/slipstream/title_bg.png" alt="title back" className="absolute" />
                    <span className="text-center z-0">{slipStreamData[index].title}</span>
                  </div>
                  <div className="item-box grid sm:grid-cols-2 gap-2 md:gap-4 absolute left-10 right-10 sm:left-16 sm:right-16 md:left-24 md:right-24 lg:left-32 lg:right-32 xl:left-36 xl:right-36 top-14 sm:top-24 lg:top-32 xl:top-48 bottom-12 sm:bottom-20 lg:bottom-28 xl:bottom-32 overflow-y-auto font-lemonada mx-auto">
                    {slipStreamData[index].content.map((item, idx) => (<ContentItem item={item} key={idx} />))}
                  </div>
                </div>
                <div className="flex w-16 sm:w-28 items-center justify-center">
                  <button className="outline-none w-full" onClick={() => handleNext(true)}>
                    <img src="/slipstream/btn_next.png" alt="btn next" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
