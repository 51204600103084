// Core React
import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Slipstream from "@components/partials/Slipstream"

// Render
const SlipstreamPage = () => (
  <Layout
    title="Slipstream || WhelpS NFT"
    contentClassName="px-0 max-w-full bg-secondary-whelps"
    page="slipstream"
  >
    <Slipstream />
  </Layout>
)
export default SlipstreamPage
